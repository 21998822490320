import { useSelector } from "react-redux";
import axiosFetch from "../services/axios";

import PAGE_ROUTES from "../config/page_routes";
import { account_id } from "../config/constants";
import { access_token } from "../utils/constants";
import { removeLocalStorage } from "../utils/helpers/helper";

const useAxiosInterceptor = () => {
	const Toast = useSelector((s) => s.toast);

	axiosFetch.interceptors.request.use(
		(req) => req,
		async (error) => {
			return error;
		}
	);

	axiosFetch.interceptors.response.use(
		(res) => res,
		async (error) => {
			if (error.response.status == 401) {
				removeLocalStorage(access_token);
				removeLocalStorage(account_id);
				window.location.replace(PAGE_ROUTES.LOGIN);
				Toast.fire({
					title: error.response.data.error_text || error.response.data.message,
					icon: "info",
				});
			}
			if (error.response.status == 400) {
				Toast.fire({
					title: error.response.data.message,
					icon: "info",
				});
			}
			return error;
		}
	);

	return [];
};

export default useAxiosInterceptor;
