import { useMutation } from "@tanstack/react-query";
import TenantHandler from "../handlers/tenant";

const useBureauRiskAssessment = (onSuccess, onFailure, onPending) => {
	const tenantHandler = new TenantHandler();

	const { data, isSuccess, isError, error, mutate } = useMutation({
		mutationFn: (payload) =>
			tenantHandler.getCuRiskAssessment({ graph_category: "bureau_score" }),
		onSuccess: onSuccess,
		onError: onFailure,
		onMutate: onPending,
	});

	return { data, isSuccess, isError, error, mutate };
};

export default useBureauRiskAssessment;
