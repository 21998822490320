export const auth_token = "auth_token";
export const account_id = "account_id";
export const CUADMIN = "cuadmin_dash";
export const SUPER_ADMIN = "superadmin";
export const TENANT_ADMIN = "admin";

export const BASE_URL = {
	DEV: "https://admin-backend-api-dev.confirmu.com",
	PROD: "https://analytics-dashboard-backend.confirmu.com",
};

export const roles = [
	{
		value: "tenant_staff_write",
		label: "Tenant Staff Write",
	},
	{
		value: "tenant_staff_read_only",
		label: "Tenant Staff ReadOnly",
	},
];
