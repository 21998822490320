import { useMutation } from "@tanstack/react-query";
import TenantHandler from "../handlers/tenant";

const useGetUserIds = (onSuccess, onFailure) => {
	const tenantHandler = new TenantHandler();

	const { data, isPending, isSuccess, isError, error, mutate } = useMutation({
		mutationFn: (payload) => tenantHandler.getUserIds(payload),
		mutationKey: ["get_user_ids"],
		onSuccess: onSuccess,
		onError: onFailure,
	});

	return { data, isPending, isSuccess, isError, error, mutate };
};

export default useGetUserIds;
