import React from "react";
import {
	categoryData,
	catgeoryLegendDescription,
	profileVisualData,
} from "../../utils/data/behaviouralData";
import AppBarChart from "../../components/AppBarChart";
import DonutLegendCard from "../../components/DonutLegendCard";
import { Box } from "@mui/material";

const BehaviorAnalysis = () => {
	return (
		<div style={{ width: "100%" }}>
			<AppBarChart
				title={"Behavioral Profile Visualization"}
				subtitle={""}
				data={profileVisualData}
			/>
			<br />
			<div style={{ display: "flex", gap: "20px" }}>
				<div style={{ width: "55%" }}>
					<AppBarChart
						title={"Behavioral Categories Chart"}
						subtitle={""}
						data={categoryData}
					/>
				</div>
				<Box
					sx={{
						display: "flex",
						width: "45%",
						flexWrap: "wrap",
						gap: "5px",
						my: 1,
					}}>
					{catgeoryLegendDescription?.map((item, index) => (
						<DonutLegendCard
							title={item.name}
							description={item.description}
							key={item.name}
						/>
					))}
				</Box>
			</div>
		</div>
	);
};

export default BehaviorAnalysis;
