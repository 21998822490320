import React, { useState } from "react";
import useGetScoreRiskSummary from "../../hooks/useGetScoreRiskSummary";
import useTenantsList from "../../hooks/useTenantsList";
import { Box, CircularProgress, Typography } from "@mui/material";
import AppSelect from "../../components/AppSelect";
import { isSuperAdmin } from "../../utils/helpers/helper";
import { useSelector } from "react-redux";
import tenantsMenuFormatter from "../../utils/dataFormatter/tenantsMenuFormatter";
import AppTextField from "../../components/AppTextField";
import AppButton from "../../components/AppButton";
import AppLineChart from "../../components/AppLineChart";
import {
	scoreDistributionByRisk,
	scoreDistributionUniqueAndCummulative,
	spotAndAcceptPortfolioRiskDistribution,
} from "../../utils/dataFormatter/graphDataFormatter";

const ScoreRiskSummary = () => {
	const authUser = useSelector((s) => s.authUser);

	const [inputs, setInputs] = useState({
		tenantSlug: "",
		cut_off_set_at: 500,
	});

	const [touched, setTouched] = useState({
		tenantSlug: false,
		cut_off_set_at: false,
	});

	const [errors, setErrors] = useState({
		tenantSlug: false,
		cut_off_set_at: false,
	});

	const onSuccess = (data) => { };
	const onFailure = () => { };

	const handleChange = (name, value) => {
		setInputs({
			...inputs,
			[name]: value,
		});
	};

	const handleBlur = (name, value) => {
		setTouched({
			...touched,
			[name]: true,
		});

		if (!value) {
			setErrors({
				...errors,
				[name]: `${name} is required`,
			});
		} else {
			setErrors({
				...errors,
				[name]: false,
			});
		}
	};

	const { data: tenantList } = useTenantsList();

	const {
		data: scoreRiskSummaryData,
		isPending,
		mutate,
	} = useGetScoreRiskSummary(onSuccess, onFailure);

	const handleSubmit = (e) => {
		e.preventDefault();

		const payload = {
			...inputs,
			cut_off_set_at: Number(inputs.cut_off_set_at),
		};
		mutate({
			...payload,
		});
	};

	return (
		<>
			<Box
				sx={{
					width: "450px",
					backgroundColor: "white",
					px: "2rem",
					py: "2rem",
					paddingTop: "1rem",
					borderRadius: "18px",
				}}>
				<Typography sx={{ fontSize: 20, fontWeight: "bold", my: "0.5rem" }}>
					Score Risk Summary.
				</Typography>
				<div>
					<form onSubmit={handleSubmit}>
						{isSuperAdmin(authUser?.role) ? (
							<AppSelect
								name="tenantSlug"
								label={"Select Tenant"}
								onChange={(e) => handleChange("tenantSlug", e.target.value)}
								onBlur={handleBlur}
								value={inputs.tenantSlug}
								menuItems={tenantsMenuFormatter(tenantList)}
							/>
						) : (
							<></>
						)}

						<AppTextField
							name={"cut_off_set_at"}
							type="number"
							label="Cut off"
							value={inputs.cut_off_set_at}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors}
							touched={touched}
						/>
						{isPending ? (
							<CircularProgress />
						) : (
							<AppButton type="submit">Fetch Summary</AppButton>
						)}
					</form>
				</div>
			</Box>

			{scoreRiskSummaryData?.status == 200 ? (
				<>
					{scoreRiskSummaryData?.data?.data[`cut-off_set_data`] ? (
						<Box
							sx={{
								mt: 2,
								p: 2,
								backgroundColor: "white",
								borderRadius: "20px",
							}}>
							<Typography sx={{ fontSize: 18 }}>
								{`Accepted Rate : `}
								<strong
									style={{
										fontSize: 20,
									}}>
									{
										scoreRiskSummaryData?.data?.data[`cut-off_set_data`].accept_rate == "NA" ? "0" :
											scoreRiskSummaryData?.data?.data[`cut-off_set_data`].accept_rate
									}
								</strong>
							</Typography>
							<Typography sx={{ fontSize: 18 }}>
								{`Score Cutoff is around : `}
								<strong
									style={{
										fontSize: 20,
									}}>
									{
										scoreRiskSummaryData?.data?.data[`cut-off_set_data`].risk_is_around == "NA" ? "0%"
											:
											`${scoreRiskSummaryData?.data?.data[`cut-off_set_data`].risk_is_around}%`
									}
								</strong>
							</Typography>
						</Box>
					) : (
						<></>
					)}

					<Box sx={{ mt: 2 }}>
						<AppLineChart
							pointRadius={0}
							title={"Score Distribution - Unique and Cummulative"}
							subtitle={""}
							data={scoreDistributionUniqueAndCummulative(
								scoreRiskSummaryData?.data?.data
							)}
						/>
					</Box>

					<Box sx={{ mt: 2 }}>
						<AppLineChart
							pointRadius={0}
							title={"Score Distribution By Risk"}
							subtitle={""}
							data={scoreDistributionByRisk(scoreRiskSummaryData?.data?.data)}
						/>
					</Box>

					<Box sx={{ mt: 2 }}>
						<AppLineChart
							pointRadius={0}
							title={"Spot and Accepted Portfolio Risk Distribution"}
							subtitle={""}
							data={spotAndAcceptPortfolioRiskDistribution(
								scoreRiskSummaryData?.data?.data
							)}
						/>
					</Box>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export default ScoreRiskSummary;
