import TenantService from "../../services/tenant";

class TenantHandler {
	constructor() {
		this.service = new TenantService();
	}

	async getAllTenants() {
		try {
			let result = await this.service.getAllTenants();
			return result;
		} catch (err) {
			return err;
		}
	}

	async getPaymentHistory() {
		try {
			let result = await this.service.getPaymentHistory();
			return result;
		} catch (err) {
			return err;
		}
	}

	async generateReport(payload, axiosOptions) {
		try {
			let result = await this.service.generateReport(payload, axiosOptions);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getCuAdminTotalGames() {
		try {
			let result = await this.service.getCuAdminTotalGames();
			return result;
		} catch (err) {
			return err;
		}
	}

	async getTenantTotalGamesPlayed(payload) {
		try {
			let result = await this.service.getTenantTotalGamesPlayed(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getAdminMonthlyGrowthAndCount() {
		try {
			let result = await this.service.getAdminMonthlyGrowthAndCount();
			return result;
		} catch (err) {
			return err;
		}
	}

	async getAdminMonthlyIncompleteGames() {
		try {
			let result = await this.service.getAdminMonthlyIncompleteGames();
			return result;
		} catch (err) {
			return err;
		}
	}

	async getAdminMonthlyHighCutOff() {
		try {
			let result = await this.service.getAdminMonthlyHighCutOff();
			return result;
		} catch (err) {
			return err;
		}
	}

	async getAdminMonthlyLowCutOff() {
		try {
			let result = await this.service.getAdminMonthlyLowCutOff();
			return result;
		} catch (err) {
			return err;
		}
	}

	async getTenantMontlyGrowth(payload) {
		try {
			let result = await this.service.getTenantMontlyGrowth(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async uploadPortfolioData(payload) {
		try {
			let result = await this.service.uploadPortfolioData(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async scoreByPercentile(payload) {
		try {
			let result = await this.service.scoreByPercentile(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getCuRiskAssessment(payload) {
		try {
			let result = await this.service.getCuRiskAssessment(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getBureauRiskAssessment(payload) {
		try {
			let result = await this.service.getBureauRiskAssessment(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getCuNoBureauRiskAssessment(payload) {
		try {
			let result = await this.service.getCuNoBureauRiskAssessment(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getConfusionMatrix(payload) {
		try {
			let result = await this.service.getConfusionMatrix(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getInternalUsers() {
		try {
			let result = await this.service.getInternalUsers();
			return result;
		} catch (err) {
			return err;
		}
	}

	async getUserIds(payload) {
		try {
			let result = await this.service.getUserIds(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getUserGameInfo(payload) {
		try {
			let result = await this.service.getUserGameInfo(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getScoreRiskSummary(payload) {
		try {
			let result = await this.service.getScoreRiskSummary(payload);
			return result;
		} catch (err) {
			return err;
		}
	}
}

export default TenantHandler;
