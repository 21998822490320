import React, { useState, useRef, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";

import AppDatePicker from "../../components/AppDatePicker";
import AppSelect from "../../components/AppSelect";
import AppButton from "../../components/AppButton";
import GeneratingReportModal from "../../components/GeneratingReportModal";
import AppCheckbox from "../../components/AppCheckbox";

import useTenantsList from "../../hooks/useTenantsList";
import useTenantReport from "../../hooks/useTenantReport";

import { minDate } from "../../utils/constants";
import tenantsMenuFormatter from "../../utils/dataFormatter/tenantsMenuFormatter";
import formatDate from "../../utils/dataFormatter/dateFormatter";
import generateReportValidation from "../../validations/generateReportValidation";
import { isSuperAdmin } from "../../utils/helpers/helper";

const GamesPlayedReport = () => {
	const cancelDataDownload = useRef(null);
	const csvLink = useRef();

	const authUser = useSelector((s) => s.authUser);
	const Toast = useSelector((s) => s.toast);

	const [inputs, setInputs] = useState({
		from_date: dayjs(Date.now()),
		to_date: dayjs(Date.now()),
		required: "Completed",
		file_type: "csv",
		traits: true,
		tenant: "",
	});
	const [reportData, setReportData] = useState({ data: "", file_name: "" });
	const [downloadPercentage, setDownloadPercentage] = useState(0);
	const [openProgressModal, setOpenProgressModal] = useState(false);

	const handleChange = (value, name) => {
		setInputs({
			...inputs,
			[name]: value,
		});
	};

	const cancelDownload = () => {
		if (cancelDataDownload?.current)
			cancelDataDownload?.current("File generation cancelled");
	};

	const handleSubmit = async (e) => {
		try {
			e.preventDefault();
			const payload = {
				...inputs,
				tenant: isSuperAdmin(authUser?.role) ? inputs?.tenant : authUser?.slug,
			};
			await generateReportValidation(payload);
			setOpenProgressModal(true);
			mutate({
				from_date: formatDate(inputs.from_date),
				to_date: formatDate(inputs.to_date),
				tenant: isSuperAdmin(authUser?.role) ? inputs.tenant : authUser?.slug,
				required: inputs.required,
				file_type: inputs.file_type,
				traits: true,
			});
		} catch (err) {
			Toast.fire({
				title: err.message,
				icon: "error",
			});
		}
	};

	const onSuccess = (data) => {
		setOpenProgressModal(false);
		setDownloadPercentage(0);
		setReportData(data.data);
	};

	const onFailure = (err) => {
		setOpenProgressModal(false);
		setDownloadPercentage(0);
	};

	useEffect(() => {
		if (reportData.data) {
			csvLink.current.link.click();

			setTimeout(() => {
				setReportData({ data: "", file_name: "" });
			}, 2000);
		}
	}, [reportData.data]);

	const { data: tenantList } = useTenantsList();

	const { mutate } = useTenantReport(
		setDownloadPercentage,
		cancelDataDownload,
		onSuccess,
		onFailure
	);

	return (
		<Box
			sx={{
				width: "450px",
				backgroundColor: "white",
				px: "2rem",
				py: "2rem",
				paddingTop: "1rem",
				borderRadius: "18px",
			}}>
			<Typography sx={{ fontSize: 20, fontWeight: "bold", my: "0.5rem" }}>
				Games Played Report.
			</Typography>
			<div>
				<form onSubmit={handleSubmit}>
					<AppDatePicker
						name="from_date"
						label={"Start Date"}
						value={inputs.from_date}
						minDate={minDate}
						maxDate={dayjs(inputs.to_date)}
						onChange={(newValue) => handleChange(newValue, "from_date")}
					/>
					<AppDatePicker
						name="to_date"
						label={"End Date"}
						minDate={dayjs(inputs.from_date)}
						maxDate={dayjs(Date.now())}
						value={inputs.to_date}
						onChange={(newValue) => handleChange(newValue, "to_date")}
					/>

					{isSuperAdmin(authUser?.role) ? (
						<AppSelect
							name="tenant"
							label={"Select Tenant"}
							onChange={(e) => handleChange(e.target.value, "tenant")}
							value={inputs.tenant}
							menuItems={tenantsMenuFormatter(tenantList)}
						/>
					) : (
						<></>
					)}

					<AppCheckbox
						label={"Include Incomplete Games"}
						checked={inputs.required == "All"}
						onChange={(e) =>
							handleChange(
								inputs.required == "All" ? "Completed" : "All",
								"required"
							)
						}
					/>

					<Box sx={{ mt: 1 }}>
						<AppButton onClick={handleSubmit}>Generate</AppButton>
					</Box>
				</form>
				{openProgressModal ? (
					<GeneratingReportModal
						open={openProgressModal}
						setOpen={setOpenProgressModal}
						percentage={downloadPercentage}
						onCancel={cancelDownload}
					/>
				) : (
					<></>
				)}

				<CSVLink
					data={reportData.data}
					filename={reportData?.file_name}
					className="hidden"
					ref={csvLink}
					target="_blank"
				/>
			</div>
		</Box>
	);
};

export default GamesPlayedReport;
