import React from "react";
import PropTypes from "prop-types";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { Typography } from "@mui/material";

import "./appbarchart.scss";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
			position: "top",
		},
		title: {
			display: false,
			text: "Game Statistics",
		},
	},
};

const AppBarChart = ({ data = {}, title = "", subtitle = "" }) => {
	return (
		<div className="_bar_chart_main">
			<div style={{ marginBottom: "1rem" }}>
				<Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
					{title}
				</Typography>
				<Typography sx={{ fontSize: 14 }}>{subtitle}</Typography>
			</div>

			<Bar options={options} data={data} />
		</div>
	);
};

AppBarChart.propTypes = {
	data: PropTypes.object,
	title: PropTypes.string,
	subtitle: PropTypes.string,
};

export default AppBarChart;
