import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";

import HomeCard from "../../components/HomeCard";
import AppLineChart from "../../components/AppLineChart";
import AppBarChart from "../../components/AppBarChart";
import {
	completedGamesData,
	formatGameEngagementData,
	formatGameStatisticsData,
	formatRiskAssessmentData,
} from "../../utils/dataFormatter/graphDataFormatter";
import getTenantsCardsData from "../../utils/dataFormatter/getTenantsCardsData";
import getHomeCardsData from "../../utils/dataFormatter/getHomeCardsData";
import { isSuperAdmin } from "../../utils/helpers/helper";

import useTenantPaymentHistory from "../../hooks/useTenantPaymentHistory";
import useTenantsList from "../../hooks/useTenantsList";
import useCuAdminTotalGames from "../../hooks/useCuAdminTotalGames";
import useTenantTotalGames from "../../hooks/useTenantTotalGames";
import useAdminMonthlyGrowth from "../../hooks/useAdminMonthlyGrowth";
import useTenantMonthlyGrowth from "../../hooks/useTenantMontlyGrowth";
import useCuRiskAssessment from "../../hooks/useCuRiskAssessmnent";
import useBureauRiskAssessment from "../../hooks/useBureauRiskAssessment";
import useCuNoBureauRiskAssessment from "../../hooks/useCuNoBureauRiskAssessment";
import useAdminMonthlyIncompleteGames from "../../hooks/useAdminMonthlyIncompleteGames";
import useAdminMonthlyHighCutOff from "../../hooks/useAdminMonthlyHighCutOff";
import useAdminMonthlyLowCutOff from "../../hooks/useAdminMonthlyLowCutOff";

const Home = () => {
	const authUser = useSelector((s) => s.authUser);

	const isAdmin = isSuperAdmin(authUser?.role);

	const { data: paymentHistory } = useTenantPaymentHistory();

	const { data: tenantsList } = useTenantsList();

	const { data: totalGamesPlayed } = useCuAdminTotalGames(authUser);

	const { data: adminMonthlyGrowth } = useAdminMonthlyGrowth(authUser);
	const { data: adminMonthlyIncompleteGames } =
		useAdminMonthlyIncompleteGames(authUser);

	const { data: adminMonthlyHighCutOff } = useAdminMonthlyHighCutOff(authUser);
	const { data: adminMonthlyLowCutOff } = useAdminMonthlyLowCutOff(authUser);

	const {
		data: tenantTotalGames,
		mutate: totalGamesPlayedMutate,
		isPending: tenantTotalGamesPending,
	} = useTenantTotalGames();

	const { data: tenantMonthlyGrowth, mutate: tenantMonthlyGrowthMutate } =
		useTenantMonthlyGrowth();

	const { data: cuRiskData, mutate: cuRiskMutate } = useCuRiskAssessment();

	const { data: bureauRiskData, mutate: bureauRiskMutate } =
		useBureauRiskAssessment();

	const { data: cuNoBureauRiskData, mutate: cuNoBureauRiskMutate } =
		useCuNoBureauRiskAssessment();

	useEffect(() => {
		if (authUser?.AccountId && !isAdmin) {
			totalGamesPlayedMutate({
				tenantSlug: authUser.slug,
			});

			tenantMonthlyGrowthMutate({
				tenantSlug: authUser.slug,
			});
			cuRiskMutate();
			bureauRiskMutate();
			cuNoBureauRiskMutate();
		}
	}, [authUser]);

	return (
		<div style={{ width: "100%" }}>
			<Typography
				sx={{ fontSize: 20, fontWeight: "bold", marginBottom: "1rem" }}>
				{`Hi, ${authUser?.username?.toString().toUpperCase()}. Welcome Back!`}
			</Typography>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					gap: "1rem",
					marginBottom: "1rem",
				}}>
				{isAdmin
					? getHomeCardsData(
							paymentHistory,
							tenantsList,
							totalGamesPlayed?.totalCompletedGames
					  )?.map((data, index) => (
							<HomeCard
								key={data.id}
								index={index}
								title={data.title}
								count={data.count}
								bgColor={data.bgColor}
							/>
					  ))
					: getTenantsCardsData(
							paymentHistory,
							authUser?.slug,
							tenantTotalGames?.data?.totalCompletedGames,
							tenantTotalGamesPending
					  ).map((data, index) => (
							<HomeCard
								key={data.id}
								index={index}
								title={data.title}
								count={data.count}
								bgColor={data.bgColor}
							/>
					  ))}
			</div>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					gap: "20px",
					marginBottom: "20px",
				}}>
				<AppLineChart
					title={"North Star Metric"}
					subtitle={"Cummulative growth of games played"}
					data={
						isAdmin
							? completedGamesData(adminMonthlyGrowth)
							: completedGamesData(tenantMonthlyGrowth?.data)
					}
				/>
				{isAdmin ? (
					<AppBarChart
						title={"Game Completion Statistics"}
						subtitle={"Visualization of complete and Incomplete games"}
						data={formatGameStatisticsData(
							adminMonthlyGrowth?.monthlyCompletedGames,
							adminMonthlyIncompleteGames?.monthlyIncompleteGames
						)}
					/>
				) : (
					<></>
				)}
			</div>
			{isAdmin ? (
				<div>
					<AppLineChart
						title={"Game Engagement statistics"}
						subtitle={"Games played with respect to engagement time"}
						data={formatGameEngagementData(
							adminMonthlyHighCutOff?.monthlyGoodGames,
							adminMonthlyLowCutOff?.monthlyBadGames
						)}
					/>
				</div>
			) : (
				<></>
			)}
			{!isAdmin ? (
				<>
					<Box sx={{ my: 1 }}>
						<AppBarChart
							title={"CU Score Distribution vs Norm"}
							subtitle={""}
							data={formatRiskAssessmentData(cuRiskData?.data)}
						/>
					</Box>
					<Box sx={{ my: 1 }}>
						<AppBarChart
							title={"Bureau Score Distribution vs Norm"}
							subtitle={""}
							data={formatRiskAssessmentData(bureauRiskData?.data)}
						/>
					</Box>
					<Box sx={{ my: 1 }}>
						<AppBarChart
							title={"CU Score Distribution of No Bureau Score Consumer"}
							subtitle={""}
							data={formatRiskAssessmentData(cuNoBureauRiskData?.data)}
						/>
					</Box>
				</>
			) : (
				<></>
			)}
		</div>
	);
};

export default Home;
