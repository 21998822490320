import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TableViewRoundedIcon from "@mui/icons-material/TableViewRounded";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import CompareArrowsRoundedIcon from "@mui/icons-material/CompareArrowsRounded";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Groups3Icon from "@mui/icons-material/Groups3";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";

import PAGE_ROUTES from "../../config/page_routes";
import { TENANT_ADMIN } from "../../config/constants";

const getDrawerItems = (isSuperAdmin, role) => [
	{
		id: 1,
		title: "Home",
		Icon: <HomeRoundedIcon color="primary" />,
		route: PAGE_ROUTES.HOME,
		clientVisible: true,
	},
	{
		id: 2,
		title: "Games Played Report",
		Icon: <DownloadRoundedIcon color="primary" />,
		route: PAGE_ROUTES.GAMES_PLAYED_REPORT,
		clientVisible: true,
	},
	{
		id: 3,
		title: "Individual Report",
		Icon: <SimCardDownloadIcon color="primary" />,
		route: PAGE_ROUTES.INDIVIDUAL_REPORT,
		clientVisible: true,
	},
	{
		id: 4,
		title: isSuperAdmin ? "Tenants Information" : "User Information",
		route: PAGE_ROUTES.TENANTS_INFORMATION,
		Icon: <Groups3Icon color="primary" />,
		clientVisible: true,
	},
	{
		id: 5,
		title: "Confusion Matrix",
		Icon: <AutoGraphIcon color="primary" />,
		route: PAGE_ROUTES.CONFUSION_MATRIX,
		clientVisible: isSuperAdmin,
	},
	{
		id: 6,
		title: "Loan Revenue & Risk Matrix",
		Icon: <TableViewRoundedIcon color="primary" />,
		route: PAGE_ROUTES.RISK_MATRIX,
		clientVisible: isSuperAdmin,
	},
	{
		id: 7,
		title: "Comparable Score Matrix",
		Icon: <CompareArrowsRoundedIcon color="primary" />,
		route: PAGE_ROUTES.COMPARABLE_SCORE_MATRIX,
		clientVisible: false,
	},
	{
		id: 8,
		title: "Score Risk Summary",
		Icon: <TrendingUpIcon color="primary" />,
		route: PAGE_ROUTES.SCORE_RISK_SUMMARY,
		clientVisible: true,
	},
	{
		id: 9,
		title: "Behaviour Analysis",
		Icon: <LeaderboardRoundedIcon color="primary" />,
		route: PAGE_ROUTES.BEHAVIOUR_ANALYSIS,
		clientVisible: true,
	},
	{
		id: 10,
		title: "User Management",
		Icon: <ManageAccountsIcon color="primary" />,
		route: PAGE_ROUTES.USER_MANAGEMENT,
		clientVisible: role == TENANT_ADMIN,
	},
	{
		id: 11,
		title: "Upload Data",
		Icon: <UploadFileIcon color="primary" />,
		route: PAGE_ROUTES.UPLOAD_DATA,
		clientVisible: isSuperAdmin,
	},
];

export default getDrawerItems;
