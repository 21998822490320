import { sumUp } from "../helpers/helper";

const completedGamesData = (data) => {
	return {
		labels: data?.monthlyCompletedGames
			? Object.keys(data?.monthlyCompletedGames)
			: [],
		datasets: [
			{
				fill: true,
				label: `Completed Games`,
				lineTension: 0.4,
				data: data?.monthlyCompletedGames
					? sumUp(Object.values(data?.monthlyCompletedGames))
					: [],
				borderColor: "rgb(53, 162, 235)",
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
		],
	};
};

const scoreDistributionUniqueAndCummulative = (data) => {
	return {
		labels: data?.overall_data
			? data.overall_data.map(i => i.score)
			: [],
		datasets: [
			{
				fill: true,
				label: `Distribution`,
				lineTension: 0.2,
				data: data?.overall_data
					? data?.overall_data?.map(i => i.percentage)
					: [],
				borderColor: "rgb(0,128,0)",
				borderWidth: 2,
				backgroundColor: "rgba(0,128,0,0.4)",
			},
			{
				fill: true,
				label: `Cummulative Distribution`,
				lineTension: 0.2,
				data: data?.overall_data
					? data?.overall_data?.map(i => i.cumulative_up_percentage)
					: [],
				borderColor: "rgb(65,105,225)",
				borderWidth: 2,
				backgroundColor: "rgba(65,105,225,0.4)",
			},
		],
	};
};


const scoreDistributionByRisk = (data) => {
	return {
		labels: data?.overall_data
			? data.overall_data.map(i => i.score)
			: [],
		datasets: [
			{
				fill: true,
				label: `Spot Bad rate`,
				lineTension: 0.6,
				data: data?.overall_data
					? data?.overall_data?.map(i => i.probability_of_bad)
					: [],
				borderColor: "rgb(220,20,60)",
				borderWidth: 2,
				backgroundColor: "rgba(220,20,60,0.2)",
			},
			{
				fill: true,
				label: `Cummulative Distribution`,
				lineTension: 0.6,
				data: data?.overall_data
					? data?.overall_data?.map(i => i.cumulative_down_percentage)
					: [],
				borderColor: "rgb(128,128,128)",
				borderWidth: 2,
				backgroundColor: "rgba(128,128,128,0.2)",
			},
		],
	};
};

const spotAndAcceptPortfolioRiskDistribution = (data) => {
	return {
		labels: data?.overall_data
			? data.overall_data.map(i => i.score)
			: [],
		datasets: [
			{
				fill: true,
				label: `Bad Rate of Accepted Portfolio`,
				borderDash: [10, 10],
				lineTension: 0.6,
				data: data?.overall_data
					? data?.overall_data?.map(i => i.avg_prob_bad_remaining)
					: [],
				borderColor: "rgb(220,20,60)",
				backgroundColor: "rgba(220,20,60,0.2)",
				borderWidth: 2,
			},
			{
				fill: true,
				label: `Spot Bad rate`,
				lineTension: 0.6,
				data: data?.overall_data
					? data?.overall_data?.map(i => i.probability_of_bad)
					: [],
				borderColor: "rgb(220,20,60)",
				backgroundColor: "rgba(220,20,60,0.2)",
				borderWidth: 2
			},
		],
	};
};


const formatRiskAssessmentData = (data) => {
	let currentTenantData = data?.current_tenant_data?.data;
	let overallData = data?.overall_data?.data;
	let formattedData = [];

	currentTenantData?.forEach((t) => {
		let totalData = overallData.find((o) => o.xLabel == t.xLabel);
		formattedData.push({
			...totalData,
			...t,
		});
	});

	return {
		labels: formattedData?.map((t) => t.xLabel) || [],
		datasets: [
			{
				label: `Total Percentage`,
				data: formattedData?.map((t) => t.total) || [],
				backgroundColor: "#858484",
			},
			{
				label: `Client Percentage`,
				data: formattedData?.map((t) => t.client) || [],
				borderColor: "rgb(53, 162, 235)",
				backgroundColor: (data) =>
					formattedData[data.dataIndex]?.clientFillColor,
			},
		],
	};
};

const formatGameStatisticsData = (completedGames, incompleteGames) => {
	return {
		labels: completedGames ? Object.keys(completedGames) : [],
		datasets: [
			{
				label: "Completed Games",
				data: completedGames ? Object.values(completedGames) : [],
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
			{
				label: "Incomplete Games",
				data: incompleteGames ? Object.values(incompleteGames) : [],
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
		],
	};
};

const formatGameEngagementData = (goodGames, badGames) => {
	return {
		labels: goodGames ? Object.keys(goodGames) : [],
		datasets: [
			{
				label: "Good Games",
				lineTension: 0.4,
				data: goodGames ? Object.values(goodGames) : [],
				borderColor: "rgb(53, 162, 235)",
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
			{
				label: "Bad Games",
				lineTension: 0.4,
				data: badGames ? Object.values(badGames) : [],
				borderColor: "rgb(255, 99, 132)",
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
		],
	};
};

export {
	completedGamesData,
	formatGameEngagementData,
	formatRiskAssessmentData,
	formatGameStatisticsData,
	scoreDistributionByRisk,
	scoreDistributionUniqueAndCummulative,
	spotAndAcceptPortfolioRiskDistribution
};
